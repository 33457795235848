<template>
  <div class="region-connect" :class="[{'active': active}]">
    <div class="overlay" @click="hide"></div>
    <div class="panel bg-grey-100">
      <div class="close text-right mb-8 cursor-pointer">
        <svg @click="hide" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.165 29.165" class="stroke-current inline-block hover:text-grey-500">
          <g id="X" transform="translate(0.582 0.582)">
            <path id="Stroke_1" data-name="Stroke 1" d="M0,0,28,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>
            <path id="Stroke_3" data-name="Stroke 3" d="M28,0,0,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>
          </g>
        </svg>
      </div>
      <div class="winery-name text-grey-500">{{ region.elements.name.value }}</div>
      <div class="heading">Connect<br>with us</div>
      <div class="intro text-grey-500">We’d love to talk to you about all our region has to offer. Simply ask us about:</div>
      <form @submit.prevent="submitForm">
        <div class="form-heading">Your enquiry type</div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.ourWine">
              <div class="filler"></div>
            </div>
            <div class="select-none">Our wine</div>
          </label>
        </div>
        <div class="field" v-show="form.ourWine">
          <textarea v-model="form.ourWineComments" class="input-textarea-connect" placeholder="If you are interested in a particular wine please include the wine name."></textarea>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.ourWineComments.required && $v.form.ourWineComments.$dirty">Please enter some comments</div>
        </div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.ourWineries">
              <div class="filler"></div>
            </div>
            <div class="select-none">Our wineries</div>
          </label>
        </div>
        <div class="field" v-show="form.ourWineries">
          <textarea v-model="form.ourWineriesComments" class="input-textarea-connect" placeholder="If you are interested in a particular winery please include the name."></textarea>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.ourWineriesComments.required && $v.form.ourWineriesComments.$dirty">Please enter some comments</div>
        </div>
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.ourRegion">
              <div class="filler"></div>
            </div>
            <div class="select-none">Our region</div>
          </label>
        </div>
        <div class="field" v-show="form.ourRegion">
          <textarea id="ourRegionComments" v-model="form.ourRegionComments" class="input-textarea-connect" placeholder="If you are interested in a particular region please include the region name."></textarea>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.ourRegionComments.required && $v.form.ourRegionComments.$dirty">Please enter some comments</div>
        </div>


        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.generalEnquiry">
              <div class="filler"></div>
            </div>
            <div class="select-none">General enquiry</div>
          </label>
        </div>
        <div class="field" v-show="form.generalEnquiry">
          <textarea id="comments" v-model="form.comments" class="input-textarea-connect" placeholder="Include the product, region or varieties you’re interested in…"></textarea>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.comments.required && $v.form.comments.$dirty">Please enter some comments</div>
        </div>


        <div class="form-heading">Your details</div>

        <div class="field">
          <label for="firstName">First name</label>
          <input
              id="firstName"
              type="text"
              name="firstName"
              placeholder="First name*"
              v-model="form.firstName"
              @input="$v.form.firstName.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.firstName.$invalid && $v.form.firstName.$dirty }, { 'success': $v.form.firstName.$dirty && !$v.form.firstName.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.firstName.required && $v.form.firstName.$dirty">First name is required</div>
        </div>
        <div class="field">
          <label for="lastName">Last name</label>
          <input
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Last name*"
              v-model="form.lastName"
              @input="$v.form.lastName.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.lastName.$invalid && $v.form.lastName.$dirty }, { 'success': $v.form.lastName.$dirty && !$v.form.lastName.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.lastName.required && $v.form.lastName.$dirty">Last name is required</div>
        </div>
        <div class="field">
          <label for="email">Email address</label>
          <input
              id="email"
              type="email"
              name="email"
              placeholder="Email address*"
              v-model="form.email"
              @input="$v.form.email.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.email.$invalid && $v.form.email.$dirty }, { 'success': $v.form.email.$dirty && !$v.form.email.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.email.required && $v.form.email.$dirty">Email address is required</div>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.email.email && $v.form.email.$dirty">Invalid email address</div>
        </div>
        <div class="field">
          <label for="role">Job title</label>
          <input
              id="role"
              type="text"
              name="role"
              placeholder="Occupation / Role*"
              v-model="form.role"
              @input="$v.form.role.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.role.$invalid && $v.form.role.$dirty }, { 'success': $v.form.role.$dirty && !$v.form.role.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.role.required && $v.form.role.$dirty">Occupation / Role is required</div>
        </div>
        <div class="field">
          <label for="company">Company</label>
          <input
              id="company"
              type="text"
              name="role"
              placeholder="Company*"
              v-model="form.company"
              @input="$v.form.company.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.company.$invalid && $v.form.company.$dirty }, { 'success': $v.form.company.$dirty && !$v.form.company.$invalid }]"
          />
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.role.required && $v.form.role.$dirty">Occupation / Role is required</div>
        </div>
        <div class="field">
          <label for="country">Country</label>
          <select
              id="country"
              type="text"
              name="country"
              placeholder="Country*"
              v-model="form.country"
              @change="$v.form.country.$touch"
              class="input-text-connect"
              :class="[{ 'error': $v.form.country.$invalid && $v.form.country.$dirty }, { 'success': $v.form.country.$dirty && !$v.form.country.$invalid }]
                      ">
            <option value="">Country*</option>
            <option value="Afganistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bonaire">Bonaire</option>
            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Canary Islands">Canary Islands</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Channel Islands">Channel Islands</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos Island">Cocos Island</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote DIvoire">Cote DIvoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Curaco">Curacao</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands">Falkland Islands</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Ter">French Southern Ter</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Great Britain">Great Britain</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="Indonesia">Indonesia</option>
            <option value="India">India</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea North">Korea North</option>
            <option value="Korea Sout">Korea South</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macau">Macau</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Malawi">Malawi</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Midway Islands">Midway Islands</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Nambia">Nambia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherland Antilles">Netherland Antilles</option>
            <option value="Netherlands">Netherlands (Holland, Europe)</option>
            <option value="Nevis">Nevis</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau Island">Palau Island</option>
            <option value="Palestine">Palestine</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Phillipines">Philippines</option>
            <option value="Pitcairn Island">Pitcairn Island</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Republic of Montenegro">Republic of Montenegro</option>
            <option value="Republic of Serbia">Republic of Serbia</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="St Barthelemy">St Barthelemy</option>
            <option value="St Eustatius">St Eustatius</option>
            <option value="St Helena">St Helena</option>
            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
            <option value="St Lucia">St Lucia</option>
            <option value="St Maarten">St Maarten</option>
            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
            <option value="Saipan">Saipan</option>
            <option value="Samoa">Samoa</option>
            <option value="Samoa American">Samoa American</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Tahiti">Tahiti</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Erimates">United Arab Emirates</option>
            <option value="United States of America">United States of America</option>
            <option value="Uraguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Vatican City State">Vatican City State</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
            <option value="Wake Island">Wake Island</option>
            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
            <option value="Yemen">Yemen</option>
            <option value="Zaire">Zaire</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
          <div class="block text-label text-red-600 mt-1" v-if="!$v.form.country.required && $v.form.country.$dirty">Country is required</div>
        </div>


<!--        <div class="field">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.virtualWineryTours">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">Virtual winery tours</div>-->
<!--          </label>-->
<!--        </div>-->





<!--        <div class="field">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.salesEnquiry">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">Sales Enquiry</div>-->
<!--          </label>-->
<!--        </div>-->
<!--        <div class="field">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.other">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">Other</div>-->
<!--          </label>-->
<!--        </div>-->


<!--        <div class="field mt">-->
<!--          <label class="flex justify-start items-start text-base cursor-pointer">-->
<!--            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">-->
<!--              <input type="checkbox" @change="validateEnquiryType" class="opacity-0 absolute" v-model="form.iAgreeToAllowMyContactInformationToBeSentWithThisEnquiry">-->
<!--              <div class="filler"></div>-->
<!--            </div>-->
<!--            <div class="select-none">I agree to allow my contact information to be sent with this enquiry.</div>-->
<!--          </label>-->
<!--        </div>-->
        <div class="field">
          <label class="flex justify-start items-start text-base cursor-pointer">
            <div class="border rounded border-white w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4 relative mt-px">
              <input type="checkbox" class="opacity-0 absolute" v-model="form.sendACopyToMyEmailAddress">
              <div class="filler"></div>
            </div>
            <div class="select-none">Send a copy to my email address.</div>
          </label>
        </div>
        <div class="block text-label text-red-600 mt-4" v-if="!$v.form.enquiryType.required && $v.form.enquiryType.$dirty">Please select an enquiry type</div>

        <input type="hidden" v-model="form.enquiryType">
        <input type="hidden" v-model="form.region">
        <input type="hidden" v-model="form.regionId">

        <div class="submit">
          <button type="submit" class="btn-transparent btn-wide">Send</button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { Formio } from 'formiojs';
import Bus from '@/events/EventBus';
import * as dayjs from 'dayjs'
import Analytics from '@/analytics'
import Vue from 'vue'
import Vuelidate from 'vuelidate/dist/vuelidate.min'
import { required, email, requiredIf } from 'vuelidate/dist/validators.min'
Vue.use(Vuelidate)

export default {
  name: 'RegionConnect',
  props: {
    region: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    tomorrow () {
      const d = new Date()
      const t = new Date(d)
      t.setDate(t.getDate() + 1)
      return t.toISOString()
    },
    preferredTime () {
      if (this.form.preferredTime.length) {
        return new Date(this.form.preferredTime).toLocaleTimeString('en-AU')
      }
      return null
    },
    preferredDate () {
      if (this.form.preferredDate.length) {
        return dayjs(this.form.preferredDate).format('DD/MM/YYYY')
      }
      return null
    },
    enquiryType () {
      let enq = []
      if (this.form.ourRegion) {
        enq.push('Our Region')
      }
      if (this.form.generalEnquiry) {
        enq.push('General enquiry')
      }
      if (this.form.ourWineries) {
        enq.push('Our Wineries')
      }
      if (this.form.ourWine) {
        enq.push('Our Wine')
      }
      return enq.join(', ')
    }
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      company: {
        required
      },
      role: {
        required
      },
      country: {
        required
      },
      enquiryType: {
        required
      },
      ourWineComments: {
        required: requiredIf((form) => {
          return form.ourWine
        })
      },
      ourWineriesComments: {
        required: requiredIf((form) => {
          return form.ourWineries
        })
      },
      ourRegionComments: {
        required: requiredIf((form) => {
          return form.ourRegion
        })
      },
      comments: {
        required: requiredIf((form) => {
          return form.generalEnquiry
        })
      }
    }
  },
  data () {
    return {
      active: false,
      loading: false,
      form: {
        regionId: this.region.elements.region_id.value,
        region: this.region.elements.name.value,
        regionEmail: this.region.elements.connect_key_contact_email.value,
        enquiryType: '',
        ourRegion: false,
        ourWine: false,
        ourWineries: false,
        mediaEnquiry: false,
        ourWineComments: '',
        ourWineriesComments: '',
        ourRegionComments: '',
        comments: '',
        sendACopyToMyEmailAddress: false,
        firstName: this.$auth.user['https://australianwine.com/first_name'] ? this.$auth.user['https://australianwine.com/first_name'] : '',
        lastName: this.$auth.user['https://australianwine.com/last_name'] ? this.$auth.user['https://australianwine.com/last_name'] : '',
        email: this.$auth.user.email ? this.$auth.user.email : '',
        role: this.$auth.user['https://australianwine.com/job_role'] ? this.$auth.user['https://australianwine.com/job_role'] : '',
        company: this.$auth.user['https://australianwine.com/company_name'] ? this.$auth.user['https://australianwine.com/company_name'] : '',
        country: this.$auth.user['https://australianwine.com/country'] ? this.$auth.user['https://australianwine.com/country'] : ''
      }
    }
  },
  methods: {
    hide () {
      this.active = false
    },
    validateEnquiryType () {
      if (this.form.ourRegion
          || this.form.ourWineries
          || this.form.ourWine
          || this.form.generalEnquiry) {
        this.form.enquiryType = 'valid'
      } else {
        this.form.enquiryType = ''
      }
    },
    validateForm () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    submitForm (e) {
      const self = this
      if (this.validateForm(e)) {
        self.loading = true
        this.$auth.getTokenSilently().then(() => {
          gtag('event', 'conversion', {
            'send_to': 'AW-741348585/mPWACK6TmMkCEOmpwOEC'
          });
          let formio = new Formio('https://bdakfyixwzkovjf.form.io/connectregion/submission');
          formio.saveSubmission({data: this.form}).then(() => {
            if (this.product) {
              Analytics.trackEvent('Connect submission', {
                category: 'ProductAction: Connect',
                label: this.product.elements.product_id.value,
                regionId: this.region.elements.region_id.value,
                productId: this.product.elements.product_id.value,
                enquiryType: this.enquiryType
              })
            } else {
              Analytics.trackEvent('Connect submission', {
                category: 'RegionAction: Connect',
                label: this.region.elements.region_id.value,
                regionId: this.region.elements.region_id.value,
                enquiryType: this.enquiryType
              })
            }
            self.loading = false
            self.hide()
            self.form.enquiryType = ''
            self.form.productInformation = false
            self.form.preferredDate = ''
            self.form.preferredTime = ''
            self.form.timezone = ''
            self.form.ourWine = false
            self.form.ourWineries = false
            self.form.ourRegion = false
            self.form.generalEnquiry = false
            self.form.ourWineComments = ''
            self.form.ourWineriesComments = ''
            self.form.ourRegionComments = ''
            self.form.comments = ''
            self.form.sendACopyToMyEmailAddress = false
            self.$v.form.$reset()

            this.$toasted.show("Enquiry sent", {
              theme: "toasted-primary",
              position: "top-right",
              duration : 5000
            });
          });
        })
      }

    }
  },
  mounted() {
    Bus.$on('region-connect', () => {
      this.active = true
    })
  },
  beforeDestroy() {
    Bus.$off('region-connect')
  }
}
</script>

<style lang="scss">
.vdatetime-popup {
  font-family: 'Hellix';
  background-color: #222;
  color: #FFF;
  position: absolute;
}
.vdatetime-popup__header {
  background-color: #111;
}
.vdatetime-calendar__month__weekday > span > span, .vdatetime-calendar__month__day > span > span {
  border-radius: 0;
}
.vdatetime-calendar__month__day:hover > span > span {
  background-color: #444;
}
.vdatetime-calendar__month__day--disabled:hover > span > span {
  background-color: #222;
}
.vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
  background-color: #E8490F;
}

.vdatetime-popup__actions__button {
  border: 1px solid #E8490F;
  color: #FFF;

  &:hover {
    background-color: #E8490F;
    color: #FFF;
  }
}
.vdatetime-time-picker__item--selected {
  color: #E8490F;
}

.vdatetime-time-picker__list {
  scrollbar-color: #333 #111;
}
.vdatetime-time-picker__list::-webkit-scrollbar-track {
  background: #111;
}
.vdatetime-time-picker__list::-webkit-scrollbar-thumb {
  background: #333;
}

.vdatetime-popup__actions {
  padding: 10px;
  background-color: #111;
}
</style>

<style lang="scss" scoped>

.field {
  margin-top: 10px;

  &.mt {
    margin-top: 20px;
  }
}

.filler {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}

input:checked + .filler {
  display: block;
}

.region-connect {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  bottom: 0;

  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(#000, 0.6);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .panel {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 450px;
    transition: all 0.3s ease;
    transform: translateX(100%);
    padding: 30px 20px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-width: 90%;

    @media(min-width: 768px)
    {
      padding: 50px 40px;
    }
  }

  &.active {
    .panel {
      transform: none;
    }
    .overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}

.winery-name {
  text-transform: uppercase;
  font-size: 14px;
  @media(min-width: 768px)
  {
    font-size: 18px;
  }
}

.heading {
  text-transform: uppercase;
  line-height: 1;
  font-size: 40px;
  margin-top: 10px;
  font-weight: bold;


  @media(min-width: 768px)
  {
    font-size: 68px;
  }
}

.intro {
  font-size: 14px;
  margin-top: 20px;
  @media(min-width: 768px)
  {
    margin-top: 30px;
  }
}

form {
  margin-top: 20px;
  @media(min-width: 768px)
  {
    margin-top: 30px;
  }
}

.form-heading {
  margin-top: 30px;
  font-weight: 600;
  @media(min-width: 768px)
  {
    margin-top: 30px;
  }
  font-size: 16px;
}

.submit {
  margin-top: 30px;
  text-align: right;
  @media(min-width: 768px)
  {
    margin-top: 40px;
  }
}
</style>
